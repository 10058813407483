
import {
  ApiHelper,
  BlueHelpComponent,
  ButtonComponent,
  Dictionary,
  FormReadDto,
  getStatusReportName,
  isNullOrUndefined,
  Logger,
  NotificationsService,
  PanelFiltersSet,
  ScreenSpinner,
  STATUS_REPORTING_FORM_NAME,
  TABLE_CLASSES,
  TABLE_ICONS,
  TableClasses,
  TableComponent,
  TableDataSet,
  TableDataSetFactory,
  TableMultiHeaders,
  toNumberOrUndefined,
  toStr,
} from "table";
import {
  defineComponent,
  PropType,
} from "vue";
import AddReportFormModal from "@/modules/registration-reports/components/AddReportFormModal/AddReportFormModal.vue";
import LoadReportForm from "@/modules/registration-reports/components/LoadReportForm.vue";
import {
  FormReadDtoExtended,
  ReportDto,
} from "@/modules/registration-reports/services/ApiServiceRegReports/types";
import { FormsExtraInfo } from "@/services/AppApiFormsService/types/FormsExtraInfo";
import ApiServiceRegReports from "@/modules/registration-reports/services/ApiServiceRegReports/ApiServiceRegReports";
import CreateReportFormModal, { SelectedReportForm } from "@/modules/registration-reports/components/CreateReportFormModal/CreateReportFormModal.vue";
import ReportFormContextMenu from "@/modules/editor-forms/components/ReportFormContextMenu/ReportFormContextMenu.vue";
import RegistrationReportsFilter
  from "@/modules/registration-reports/components/ReportPanelList/components/RegistrationReportsFilter.vue";
import AppApiFormsService from "@/services/AppApiFormsService/AppApiFormsService";
import { TableCellPointer } from "table/dist/components/TableComponent/common/types/TableCellPointer";
import { reportHelpers } from "@/modules/registration-reports/common/helpers";
import { TableRowObj } from "table/dist/types/TableRowObj";
import { wrapNotifyAndAwaited } from "@/common/helpers/wrapNotifyAndAwaited";

export interface ReportListValue {
  form: FormReadDtoExtended;
}

interface TableReportData {
  tableDataSet: TableDataSet;
  factory: TableDataSetFactory;
  open: {
    info: boolean;
  };
}

export default defineComponent({
  name: "ReportPanelListV2",
  components: {
    ScreenSpinner,
    AddReportFormModal,
    CreateReportFormModal,
    ButtonComponent,
    RegistrationReportsFilter,
    LoadReportForm,
    ReportFormContextMenu,
    BlueHelpComponent,
    TableComponent,
    TableMultiHeaders,
  },
  props: {
    tableClasses: {
      type: Object as PropType<TableClasses>,
      default: TABLE_CLASSES,
    },
  },
  setup() {
    return {
      TABLE_ICONS,
      getStatusReportName,
    };
  },
  data() {
    return {
      open: {
        filter: false,
        createReportForm: false,
        addReportForm: false,
        loadReportForm: false,
      },
      loading: {
        unload: false,
      },
      params: {
        addReportForm: {
          report: null as ReportDto | null,
        },
      },
      forms: null as FormReadDtoExtended[] | null,
      filterData: reportHelpers.getInitFilterData(),
      filterDataActual: reportHelpers.getInitFilterData(),
      listFormFiltered: undefined as undefined | ReportListValue[],
      table: null as TableReportData | null,
      heightPx: {
        regReportsWithoutTableContainer: 300,
        appHeader: 50,
        componentPadding: 36,
      },
      formsExtraInfo: null as FormsExtraInfo | null,
    };
  },
  async created() {
    window.addEventListener("resize", this.resizeHandler);
    this.initFilter(true);
    await wrapNotifyAndAwaited([
      async () => {
        this.formsExtraInfo = await AppApiFormsService.extraInfo().then(
          (x) => x.json,
        );
      },
    ]);
  },
  async mounted() {
    await this.updateReportForms();
  },
  updated() {
    this.setHeights();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  watch: {
    forms() {
      this.listFormFiltered = this.listFormFilteredActual;
    },
    "$route.query.filter"(prev, current) {
      if (toStr(prev) === toStr(current)) {
        return;
      }

      this.initFilter(true);
    },
    async listFormFiltered() {
      await this.initTable();
    },
  },
  computed: {
    isDisabledUnload(): boolean {
      return (
        !this.formsExtraInfo ||
        !this.formsExtraInfo.right_to_act.unload_exchange_file ||
        Object.keys(this.selectedForms).length === 0 ||
        this.loading.unload
      );
    },
    isDisabledUpload(): boolean {
      return (
        !this.formsExtraInfo ||
        !this.formsExtraInfo.right_to_act.upload_exchange_file ||
        Object.keys(this.selectedForms).length === 0 ||
        this.loading.unload
      );
    },
    tableContainerStyle(): Object {
      const addedHeight = Object.values(this.heightPx).reduce(
        (sum, current) => sum + current,
        0,
      );
      const height = `calc(100vh - ${addedHeight}px)`;
      return { height };
    },
    selectedValues(): TableRowObj[] {
      if (!this.table) {
        return [];
      }
      return this.table.tableDataSet.rows.filter((x) => x.select) as any;
    },
    selectedForms(): Dictionary<FormReadDtoExtended> {
      return this.selectedValues.reduce((selectedForms, tableRow) => {
        const form: FormReadDtoExtended = tableRow.original;
        selectedForms[form.report_form_id] = form;
        return selectedForms;
      }, {} as Dictionary<FormReadDtoExtended>);
    },
    listForm(): ReportListValue[] | undefined {
      return this.forms
        ?.map((form) => ({ form }))
        .sort((x, y) => y.form.report_form_id - x.form.report_form_id);
    },
    formsByReportId(): Dictionary<FormReadDtoExtended[]> {
      if (!this.forms) {
        return {};
      }

      return this.forms.reduce((v, c) => {
        if (isNullOrUndefined(c.report_id)) {
          return v;
        }

        if (v[c.report_id] === undefined) {
          v[c.report_id] = [];
        }

        v[c.report_id].push(c);
        return v;
      }, {} as Dictionary<FormReadDtoExtended[]>);
    },
    listFormFilteredActual(): ReportListValue[] | undefined {
      return this.listForm?.filter((listValue: ReportListValue) => {
        const {
          form_name,
          form_state,
          form_period_end,
          author_name,
          form_no,
          user_id,
        } = this.filterDataActual;
        if (form_name?.length) {
          if (
            !form_name.some(
              (form_name) => listValue.form.form_name === form_name.label,
            )
          ) {
            return false;
          }
        }

        if (form_state?.length) {
          if (
            !form_state.some(
              (form_state) => listValue.form.form_state === form_state.value,
            )
          ) {
            return false;
          }
        }

        if (form_period_end?.length) {
          if (
            !listValue.form.form_period_end &&
            !listValue.form.form_period_start
          ) {
            return false;
          }

          const form_period_value = +new Date(form_period_end);

          const form_period_start_num = listValue.form.form_period_start
            ? +new Date(listValue.form.form_period_start)
            : undefined;
          if (
            form_period_start_num !== undefined &&
            form_period_start_num > form_period_value
          ) {
            return false;
          }

          const form_period_end_num = listValue.form.form_period_end
            ? +new Date(listValue.form.form_period_end)
            : undefined;
          if (
            form_period_end_num !== undefined &&
            form_period_end_num < form_period_value
          ) {
            return false;
          }
        }

        if (author_name?.length) {
          if (
            !author_name.some(
              (author_name) => listValue.form.author_name === author_name.label,
            )
          ) {
            return false;
          }
        }

        if (form_no?.length) {
          if (
            !form_no.some((form_no) => listValue.form.form_no === form_no.value)
          ) {
            return false;
          }
        }

        if (user_id?.length) {
          if (
            !user_id.some(
              (user_id) =>
                listValue.form.user_id === toNumberOrUndefined(user_id.value),
            )
          ) {
            return false;
          }
        }

        return true;
      });
    },
    reportingFormsExcluded(): string[] {
      const reportId = this.params.addReportForm.report?.report_id;
      if (isNullOrUndefined(reportId)) {
        return [];
      }

      return this.formsByReportId[reportId]?.map((x) => x.form_no);
    },
  },
  methods: {
    resizeHandler() {
      this.setHeights();
    },
    setHeights() {
      const regReportsWithoutTableContainer = (
        this.$refs.regReportsWithoutTableContainer as HTMLDivElement
      )?.clientHeight;
      if (
        !isNullOrUndefined(regReportsWithoutTableContainer) &&
        regReportsWithoutTableContainer !==
        this.heightPx.regReportsWithoutTableContainer
      ) {
        this.heightPx.regReportsWithoutTableContainer =
          regReportsWithoutTableContainer;
      }
    },
    async initTable() {
      if (this.table) {
        await this.table.tableDataSet.destroy();
      }

      this.table = null;
      if (isNullOrUndefined(this.listFormFiltered)) {
        return;
      }

      const factory = new TableDataSetFactory({
        tableName: "main_table",
        uniqId: `journal-reports-form`,
        headers: [
          "author_name",
          "form_name",
          "form_period_start",
          "form_period_end",
          "user",
          "form_state",
          "context_menu",
        ],
        types: {
          base: {
            base_type: "BASE",
          },
          slot: {
            base_type: "SLOT",
          },
          date: {
            base_type: "DATE",
            display: {
              date_format: "DD.MM.YYYY",
            },
          },
          form_state: {
            base_type: "DICT",
            data_source: STATUS_REPORTING_FORM_NAME,
          },
        },
        rows: this.listFormFiltered.map((formReadDtoExtended) => {
          return formReadDtoExtended.form as Dictionary;
        }),
        edit: {},
        modelDtoArray: [
          {
            field: "author_name",
            caption: "Организация",
            type: "base",
            width: "200px",
          },
          {
            field: "form_name",
            caption: "Форма",
            type: "slot",
            width: "400px",
            slot: "form_name",
          },
          {
            field: "form_period_start",
            caption: "Дата от",
            type: "date",
            width: "100px",
          },
          {
            field: "form_period_end",
            caption: "Дата по",
            type: "date",
            width: "100px",
          },
          {
            field: "user",
            caption: "Автор",
            width: "120px",
          },
          {
            field: "form_state",
            caption: "Статус",
            type: "form_state",
            width: "110px",
          },
          {
            field: "context_menu",
            caption: "",
            type: "slot",
            width: "50px",
            slot: "context_menu",
          },
        ],
        tableDataSetOptions: {
          display: {
            incCol: 1,
            incRow: 0,
            rowIndexCol: false,
          },
          // todo доделать фильтры
          filtersSet: new PanelFiltersSet(),
          uniqueWordWrapSave: true,
        },
        enableAutoSaveSettings: true,
      });
      const tableDataSet = await factory.create();
      this.table = {
        tableDataSet,
        factory,
        open: {
          info: true,
        },
      };
    },
    initFilter(takeAwayFromUrl: boolean) {
      if (!takeAwayFromUrl) {
        this.filterData = this.filterDataActual;
        return;
      }

      const initFilter = this.$route.query.filter as string | undefined;
      if (initFilter) {
        try {
          this.filterDataActual = JSON.parse(initFilter);
          this.filterData = this.filterDataActual;
          this.listFormFiltered = this.listFormFilteredActual;
        } catch (e: any) {
          Logger.error(e);
          return;
        }

        // let query = Object.assign({}, this.$route.query);
        // delete query.filter;
        // this.$router.replace({ query });
      }
    },
    setFilterDataInUrl() {
      let query: any = Object.assign({}, this.$route.query);
      query.filter = JSON.stringify(this.filterData);
      this.$router.replace({ query });
    },

    async updateReportForms() {
      this.forms = null;
      this.listFormFiltered = undefined;
      const formsDto = await ApiServiceRegReports.getFormList();
      this.forms = formsDto.json.result;
      await this.initTable();
    },

    onFilter() {
      this.initFilter(false);
      this.setFilterDataInUrl();
      this.open.filter = false;
    },

    async onDeleteReport(report: ReportDto) {
      try {
        const result = (
          await ApiServiceRegReports.deleteReport(report.report_id)
        ).json;
        if (!result) {
          await this.updateReportForms();
        }
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при удалении отчёта",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error(e);
      }
    },

    async onSuccessDeleteForm({ form }: { form: FormReadDtoExtended }) {
      this.forms =
        this.forms?.filter((x) => x.report_form_id !== form.report_form_id) ||
        null;
    },

    async onAddReportForms({
      report,
      forms,
    }: {
      report: ReportDto;
      forms: Required<SelectedReportForm>[];
    }) {
      const result = (
        await ApiServiceRegReports.saveForm(
          forms.map((x) => ({
            report_id: report.report_id,
            form_no: x.selectValue.value,
            form_name: x.selectValue.label,
            form_period_start: x.form_period_start,
            form_period_end: x.form_period_end,
            author_id: report.author_id,
            author_name: report.author_name,
          })),
        )
      ).json;
      if (result) {
        if (!this.forms) {
          this.forms = [];
        }

        this.forms.push(...result.result);
        this.open.addReportForm = false;
      }
    },

    async onAddReport(report: ReportDto) {
      await this.updateReportForms();
    },

    onUnloadCpFile() {
      ApiServiceRegReports.formUnloadCpFile(Object.keys(this.selectedForms));
    },

    async onCreateAdjustmentReport() {
      // if (
      //   Object.values(this.selectedForms).some(
      //     (x) => !CREATE_CORRECT_FORM_ALLOW_STATUS.includes(x.form_state),
      //   )
      // ) {
      //   NotificationsService.send({
      //     type: "error",
      //     title: "Ошибка при создании корректирующего отчёта",
      //     duration: 5000,
      //     html: `
      //       Создание корректирующего отчёта доступно только для статусов:<br>
      //       <code>
      //       ${CREATE_CORRECT_FORM_ALLOW_STATUS.map(
      //         (x) => STATUS_REPORTING_FORM_NAME[x],
      //       ).join(", ")}
      //       </code>
      //     `,
      //   });
      //   return;
      // }

      this.loading.unload = true;
      try {
        await ApiServiceRegReports.formsCreateAdjustment(
          Object.keys(this.selectedForms).map(Number),
        );
        await this.updateReportForms();
      } catch (e) {
        const errorMessage = await ApiHelper.getErrorMessage(e);
        NotificationsService.send({
          type: "error",
          title:
            "Произошла ошибка при создании корректировки для отчётной формы",
          text: errorMessage,
        });
      }
      this.loading.unload = false;
    },

    onToggleSelectedReportForm(form?: FormReadDtoExtended) {
      if (form) {
        if (form.report_form_id in this.selectedForms) {
          delete this.selectedForms[form.report_form_id];
        } else {
          this.selectedForms[form.report_form_id] = form;
        }
      } else {
        if (Object.keys(this.selectedForms).length === this.forms!.length) {
          this.selectedForms = {};
        } else {
          this.forms!.forEach((form) => {
            this.selectedForms[form.report_form_id] = form;
          });
        }
      }
    },

    setHelp(
      table: TableReportData,
      pointer: Required<TableCellPointer> | null,
    ) {
      if (!pointer) {
        return;
      }

      const model = table.tableDataSet.getModelOrUndefined(pointer.col_name);
      if (!model) {
        return;
      }

      table.tableDataSet.helpHelper.setHelp(model.modelDto.help || null, {
        tableDataSet: table.tableDataSet,
        field: pointer.col_name,
      });
    },

    onSwitchRow(row: number) {
      const tableRow = this.table!.tableDataSet.getRowOrUndefined(row);
      if (!tableRow) {
        return;
      }

      tableRow.select = !tableRow.select;
    },

    onSelectedAll(mode: "" | "clear" | "all" = "") {
      if (!this.table) {
        return;
      }

      const isSelectedAll =
        this.selectedValues.length === this.table.tableDataSet.rows.length;
      if (mode === "clear" || isSelectedAll) {
        this.table.tableDataSet.rows.forEach((x) => (x.select = false));
      } else {
        this.table.tableDataSet.rows.forEach((tableRow) => {
          tableRow.select = true;
        });
      }
    },

    openContextMenuWithReportForm(event: MouseEvent, form: FormReadDto) {
      this.getReportFormContextMenuComponent().open(event, form);
    },

    getReportFormContextMenuComponent() {
      return this.$refs.reportFormContextMenu as InstanceType<
        typeof ReportFormContextMenu
      >;
    },
  },
});
