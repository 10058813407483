
import { defineComponent } from "vue";
import { TABLE_ICONS } from "table";
import ReportPanelListV2 from "@/modules/registration-reports/components/ReportPanelList/ReportPanelListV2.vue";

export default defineComponent({
  name: "RegistrationReportsDemoV2",
  components: {
    ReportPanelListV2,
  },
  setup() {
    return {
      TABLE_ICONS,
    };
  },
  data() {
    return {};
  },
});
