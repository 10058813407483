import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72b883c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reg-reports-filter" }
const _hoisted_2 = { class: "reg-reports-filter-row-1" }
const _hoisted_3 = { class: "reg-reports-filter-inputs" }
const _hoisted_4 = { class: "reg-reports-filter-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TSelectComponent = _resolveComponent("TSelectComponent")!
  const _component_TInputComponent = _resolveComponent("TInputComponent")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_TSelectComponent, {
            modelValue: _ctx.filterDataNew.author_name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterDataNew.author_name) = $event)),
            multiselect: true,
            "reselect-init-value": true,
            caption: "Организация",
            "options-url": "/api/vocs?action=list_organizations",
            onReset: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterDataNew.author_name = []))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_TSelectComponent, {
            modelValue: _ctx.filterDataNew.form_name,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterDataNew.form_name) = $event)),
            multiselect: true,
            "options-url": `/api/repository/forms/types?action=list`,
            "reselect-init-value": true,
            caption: "Отчётная форма",
            onReset: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filterDataNew.form_name = []))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_TSelectComponent, {
            modelValue: _ctx.filterDataNew.form_no,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filterDataNew.form_no) = $event)),
            multiselect: true,
            "options-url": `/api/repository/forms/types?action=list`,
            "reselect-init-value": true,
            caption: "Отчётная форма (form_no)",
            onReset: _cache[5] || (_cache[5] = ($event: any) => (_ctx.filterDataNew.form_no = []))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_TInputComponent, {
            modelValue: _ctx.filterDataNew.form_period_end,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filterDataNew.form_period_end) = $event)),
            "force-change-model-value": _ctx.filterDataNew,
            "reselect-init-value": true,
            caption: "Дата",
            type: "date",
            onReset: _cache[7] || (_cache[7] = ($event: any) => (_ctx.filterDataNew.form_period_end = ''))
          }, null, 8, ["modelValue", "force-change-model-value"]),
          _createVNode(_component_TSelectComponent, {
            modelValue: _ctx.filterDataNew.form_state,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filterDataNew.form_state) = $event)),
            multiselect: true,
            options: _ctx.STATUS_REPORTING_FORM_SELECT_VALUES,
            "reselect-init-value": true,
            caption: "Статус",
            onReset: _cache[9] || (_cache[9] = ($event: any) => (_ctx.filterDataNew.form_state = []))
          }, null, 8, ["modelValue", "options"]),
          _createVNode(_component_TSelectComponent, {
            modelValue: _ctx.filterDataNew.user_id,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.filterDataNew.user_id) = $event)),
            multiselect: true,
            "options-url": `/api/users?action=list_choice`,
            "reselect-init-value": true,
            caption: "Пользователь",
            onReset: _cache[11] || (_cache[11] = ($event: any) => (_ctx.filterDataNew.user_id = []))
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", null, "Найдено: " + _toDisplayString(_ctx.currentCount), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ButtonComponent, {
          icon: _ctx.TABLE_ICONS.closeBlue,
          class: "button-blue",
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Скрыть ")
          ]),
          _: 1
        }, 8, ["icon"]),
        _createVNode(_component_ButtonComponent, {
          icon: _ctx.TABLE_ICONS.restoreBlue,
          class: "button-blue",
          onClick: _ctx.onReset
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Очистить ")
          ]),
          _: 1
        }, 8, ["icon", "onClick"]),
        _createVNode(_component_ButtonComponent, {
          class: "form-button",
          "data-type": "accent",
          onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('on-filter')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Отфильтровать ")
          ]),
          _: 1
        })
      ])
    ])
  ]))
}